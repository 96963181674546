import React, { useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import API from "../api";

const sdk = new ChartsEmbedSDK({
  baseUrl: "https://charts.mongodb.com/charts-b2a-toayh",
});

const Stats = ({}) => {
  const [jwtToken, setJwtToken] = React.useState(null);
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    getJwtToken();
    const intervalId = setInterval(getJwtToken, 3000000);
    return () => clearInterval(intervalId);
  }, []);

  const getJwtToken = async () => {
    try {
      const { ok, data, token } = await API.get("/user/mongodb-token");
      if (!ok) return;

      setJwtToken(token);
      setUser(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!jwtToken || !user) return;

    const dashboard = sdk.createDashboard({
      dashboardId: "c9cb4488-4837-4d6e-8978-b622ad802b79",
      getUserToken: () => jwtToken,
      height: "100vh",
      width: "100vw",
    });

    dashboard.render(document.getElementById("mongodb-chart"));
  }, [user, jwtToken]);

  return <div id="mongodb-chart" />;
};

export default Stats;
