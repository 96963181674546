const environment = getEnvironment();

let API_URL = "https://api.querco.co";
let SentryUrl = "https://46a4f4b74b29f375bc5f94890b0defb4@o4505545038888960.ingest.sentry.io/4505986495873024";

if (environment === "development") {
  API_URL = "http://localhost:8080";
}

export { API_URL, SentryUrl, environment };

function getEnvironment() {
  if (window.location.href.indexOf("localhost") !== -1 || window.location.href.indexOf("127.0.0.1") !== -1) {
    return "development";
  }
  return "production";
}
