import React, { useState } from "react";

const Sandbox = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="p-4">
      <div>
        <input id="numDemande" value="querco-2024-04-22" />
        <div id="ihmBoxAnalyse">
          <div className="ihmCboxContent ihmCboxvert"></div>
          <input type="text" id="analyseCodeAjout" />
        </div>
      </div>
      <input type="text" name="patientNaissance" value="1980-01-01" />
      <input type="text" name="patientSexe" value="M" />
      <input type="date" name="ordonnanceDate" />
      <input type="text" id="medecinCodeAjout" />
      <div className="seance"></div>
      <table className="main ml-auto">
        <tbody>
          <tr>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Sandbox;
