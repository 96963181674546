import { API_URL } from "../config";

const EXAM_TYPE = {
  DEFAULT: "DEFAULT",
  H24: "H24",
  ECHANTILLON: "ECHANTILLON",
  SEROLOGIE: "SEROLOGIE",
  SANG: "SANG",
  URINE: "URINE",
  PCR: "PCR",
  SELLE: "SELLE",
  AUTRE: "AUTRE",
};

const EXAM_TYPE_COLOR_BG = {
  DEFAULT: "bg-gray-100",
  URINE: "bg-blue-100",
  ECHANTILLON: "bg-purple-100",
  H24: "bg-green-100",
  SEROLOGIE: "bg-yellow-100",
  SANG: "bg-red-100",
  PCR: "bg-pink-100",
  SELLE: "bg-violet-100",
  AUTRE: "bg-orange-100",
};

const EXAM_TYPE_COLOR_TEXT = {
  DEFAULT: "text-gray-400",
  URINE: "text-blue-400",
  ECHANTILLON: "text-purple-400",
  H24: "text-green-400",
  SEROLOGIE: "text-yellow-400",
  SANG: "text-red-400",
  PCR: "text-pink-400",
  SELLE: "text-violet-400",
  AUTRE: "text-orange-400",
};

const normalizeText = (text) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/[^a-z0-9\/\+\- ]/gi, "")
    .replace(/\s+/g, " ");
};

function levenshteinDistance(a, b) {
  const matrix = [];

  let i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  let j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
      }
    }
  }

  return matrix[b.length][a.length];
}

function similarityScore(word1, word2) {
  const distance = levenshteinDistance(word1, word2);
  const maxLen = Math.max(word1.length, word2.length);
  return (1 - distance / maxLen) * 100;
}

export { normalizeText, similarityScore, EXAM_TYPE, EXAM_TYPE_COLOR_BG, EXAM_TYPE_COLOR_TEXT };
