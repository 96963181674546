import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import API from "../api";
import { toast } from "react-hot-toast";

const Auth = () => {
  const history = useHistory();
  const [user, setUser] = useState({ email: "", password: "" });

  const signin = async () => {
    if (!user.email || !user.password) return toast.error("Veuillez remplir tous les champs");

    try {
      const res = await API.post("/user/signin", user);
      if (!res.token) return toast.error("Une erreur est survenue");

      API.setToken(res.token);

      const queryParams = new URLSearchParams(window.location.search);
      const redirectPath = queryParams.get("redirect");
      if (redirectPath) return history.push(redirectPath);

      history.push("/");
    } catch (error) {
      toast.error(error.code);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-black bg-opacity-75">
      <div className="w-96 h-56 bg-white flex justify-center items-center flex-col p-10 gap-1 rounded-md">
        <div className="font-bold text-lg mb-4">Querco Extraction</div>
        <input
          className="mb-1 p-3 w-full border-2 border-gray-300 rounded-md"
          type="email"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          placeholder="Email"
        />
        <input
          className="mb-1 p-3 w-full border-2 border-gray-300 rounded-md"
          type="password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          placeholder="Mot de passe"
        />
        <button className="px-4 py-2 bg-blue-500 text-white rounded-md" onClick={signin}>
          Se connecter
        </button>
      </div>
    </div>
  );
};

export default Auth;
