import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import API from "../api";
import { toast } from "react-hot-toast";

const Auth = () => {
  const history = useHistory();
  const [user, setUser] = useState({ password: "", passwordConfirm: "", token: "" });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    setUser((user) => ({ ...user, token }));
  }, []);

  const signin = async () => {
    if (!user.passwordConfirm || !user.password) return toast.error("Veuillez remplir tous les champs");
    if (user.password !== user.passwordConfirm) return toast.error("Les mots de passe ne correspondent pas");

    try {
      const res = await API.post("/user/forgot_password_reset", user);
      if (!res.ok) return toast.error("Une erreur est survenue");

      toast.success("Mot de passe modifié avec succès");

      history.push("/");
    } catch (error) {
      toast.error(error.code);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-black bg-opacity-75">
      <div className="min-w-96 min-h-56 bg-white flex justify-center items-center flex-col p-10 gap-1 rounded-md">
        <div className="font-bold text-xl mb-4">Querco Extraction</div>
        <div className="text text-gray-500 mb-4">Veuillez choisir un mot de passe</div>
        <input
          className="mb-1 p-3 w-full border-2 border-gray-300 rounded-md"
          type="password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          placeholder="Mot de passe"
        />
        <input
          className="mb-1 p-3 w-full border-2 border-gray-300 rounded-md"
          type="password"
          value={user.passwordConfirm}
          onChange={(e) => setUser({ ...user, passwordConfirm: e.target.value })}
          placeholder="Confirmer le mot de passe"
        />
        <button className="px-4 py-2 bg-blue-500 text-white rounded-md" onClick={signin}>
          Valider
        </button>
      </div>
    </div>
  );
};

export default Auth;
