import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiPencilAlt, HiOutlineX, HiCheck, HiOutlineTrash, HiOutlinePlus, HiOutlineExclamation, HiOutlineInformationCircle, HiExternalLink } from "react-icons/hi";

import API from "../api";
import { EXAM_TYPE, EXAM_TYPE_COLOR_BG, EXAM_TYPE_COLOR_TEXT } from "../utils";
import { API_URL } from "../config";

const DEFAULT_EXAM_TYPE_PRIORITY = [
  EXAM_TYPE.DEFAULT,
  EXAM_TYPE.SANG,
  EXAM_TYPE.URINE,
  EXAM_TYPE.ECHANTILLON,
  EXAM_TYPE.H24,
  EXAM_TYPE.SEROLOGIE,
  EXAM_TYPE.PCR,
  EXAM_TYPE.SELLE,
  EXAM_TYPE.AUTRE,
];
const PCR_EXAM_TYPE_PRIORITY = [
  EXAM_TYPE.DEFAULT,
  EXAM_TYPE.SANG,
  EXAM_TYPE.URINE,
  EXAM_TYPE.ECHANTILLON,
  EXAM_TYPE.H24,
  EXAM_TYPE.PCR,
  EXAM_TYPE.SEROLOGIE,
  EXAM_TYPE.SELLE,
  EXAM_TYPE.AUTRE,
];

const Code = ({ user }) => {
  const isAdmin = user.role === "admin" || user.role === "superadmin";
  const [table, setTable] = useState([]);
  const [unlinkedExams, setUnlinkedExams] = useState([]);
  const [selectedStats, setSelectedStats] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDuplicatesModal, setOpenDuplicatesModal] = useState(false);
  const [selectedCode, setSelectedCode] = useState();
  const [filter, setFilter] = useState({ alertOnly: false });
  const [openLogModal, setOpenLogModal] = useState(false);

  useEffect(() => {
    getData();
  }, [isAdmin]);

  useEffect(() => {
    if (!isAdmin) return;
    getUnlinkedExams();
    if (selectedStats) setSelectedStats(table.find((el) => el._id === selectedStats._id));
  }, [table]);

  const getData = async () => {
    try {
      const response = await API.get(`/table`);
      if (!response.ok) return toast.error(response.message);

      let table = response.data;
      if (isAdmin) table = await getStats(table);

      table.forEach((el) => {
        let groupCodes = {};
        el.codes.forEach((code) => {
          if (!groupCodes[code.type]) groupCodes[code.type] = [];
          groupCodes[code.type].push(code);
        });
        el.groupCodes = groupCodes;

        let groupExams = {};
        el.exams.forEach((exam) => {
          if (!groupExams[exam.type]) groupExams[exam.type] = [];
          groupExams[exam.type].push(exam);
        });
        el.groupExams = groupExams;

        el.warning = el.exams.reduce((acc, exam) => (exam?.stats?.percentage > acc ? exam?.stats?.percentage : acc), 0);
      });

      setTable(table);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  const getStats = async (table) => {
    try {
      const response = await API.get(`/table/stats`);

      if (!response.ok) return toast.error(response.message);

      for (let el of table) {
        for (let exam of el.exams) {
          exam.stats = response.data.find((stat) => stat._id === exam._id);
        }
      }

      return table;
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  const getUnlinkedExams = async () => {
    try {
      const response = await API.get(`/exam/without-link`);
      if (!response.ok) return toast.error(response.message);

      setUnlinkedExams(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  const hiddeUnlinkedExam = async (id) => {
    try {
      const response = await API.delete(`/exam/without-link/${id}`);
      if (!response.ok) return toast.error(response.message);

      await getUnlinkedExams();

      toast.success("Examen caché avec succès");
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <>
      <div className="px-4 pb-16">
        {isAdmin && (
          <div className="flex justify-between mt-4">
            <div className="flex gap-4 items-end">
              <div className="bg-orange-50 border border-orange-200 text-orange-900 rounded-md px-2 py-1 flex gap-2 items-center">
                <input type="checkbox" checked={filter.alertOnly} onChange={(e) => setFilter((prev) => ({ ...prev, alertOnly: e.target.checked }))} />
                Filtrer les alertes ({table.filter((el) => el.warning).length})
              </div>
              {/* <button type="button" className="text-blue-500 border border-blue-500 rounded-md px-2 py-1" onClick={() => setOpenDuplicatesModal(true)}>
                Supprimer les doublons
              </button> */}
            </div>
            <div className="flex gap-4 items-center">
              <button type="button" className="border border-blue-500 text-blue-500 rounded-md px-2 py-1" onClick={() => setOpenLogModal(true)}>
                Historique des changements
              </button>
            </div>
          </div>
        )}

        <div className="divide-y divide-gray-200 mt-2">
          <div className="bg-gray-50 grid grid-cols-10 px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center">
            <div className="col-span-2">Code(s)</div>
            <div className="col-span-7">Examen(s)</div>
            <div className="col-span-1 flex justify-end">
              {isAdmin && (
                <button type="button" className="text-lg text-blue-500" onClick={() => setOpenAddModal(true)}>
                  <HiOutlinePlus />
                </button>
              )}
            </div>
          </div>
          <div className="bg-white divide-y divide-gray-200">
            {table
              .filter((el) => (filter.alertOnly ? el.warning > 0 : true))
              .sort((a, b) => (filter.alertOnly ? b.warning - a.warning : 0))
              .map((el, index) => (
                <div key={index} className="grid grid-cols-10 items-center p-2">
                  <div className="flex gap-2 col-span-2 flex-wrap">
                    {Object.keys(el.groupCodes)
                      .sort((a, b) => DEFAULT_EXAM_TYPE_PRIORITY.indexOf(a) - DEFAULT_EXAM_TYPE_PRIORITY.indexOf(b))
                      .map((type) => (
                        <p key={type} className={`rounded-md ${EXAM_TYPE_COLOR_BG[type]} px-2 py-1 m-1 whitespace-pre`}>
                          <span className={`text-xs ${EXAM_TYPE_COLOR_TEXT[type]}`}>{type} : </span>
                          {el.groupCodes[type].map((code) => code.value).join(", ")}
                        </p>
                      ))}
                  </div>
                  <div className="flex gap-2 col-span-7 flex-wrap">
                    {Object.keys(el.groupExams)
                      .sort((a, b) => DEFAULT_EXAM_TYPE_PRIORITY.indexOf(a) - DEFAULT_EXAM_TYPE_PRIORITY.indexOf(b))
                      .map((type) => (
                        <p key={type} className={`w-fit rounded-md ${EXAM_TYPE_COLOR_BG[type]} px-2 py-1 m-1 `}>
                          <span className={`text-xs ${EXAM_TYPE_COLOR_TEXT[type]}`}>{type} : </span>
                          {el.groupExams[type].map((exam) => exam.value).join(", ")}
                        </p>
                      ))}
                  </div>
                  <div className="flex gap-2 col-span-1 justify-end">
                    {isAdmin && (
                      <>
                        <button type="button" onClick={() => setSelectedStats(el)}>
                          {el.warning ? <HiOutlineExclamation className="inline-block text-orange-500" /> : <HiOutlineInformationCircle className="inline-block text-blue-500" />}
                        </button>
                        <button type="button" className="text text-blue-500" onClick={() => setSelectedCode(el)}>
                          <HiPencilAlt className="inline-block" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {openAddModal && (
          <AddModal
            close={() => {
              setOpenAddModal(false);
              getData();
            }}
          />
        )}
        {selectedCode && (
          <EditModal
            selectedCode={selectedCode}
            close={() => {
              setSelectedCode(undefined);
              getData();
            }}
          />
        )}
        {selectedStats && <StatsModal selectedStats={selectedStats} close={() => setSelectedStats(null)} key={selectedStats.percentage} getData={getData} />}
        {openDuplicatesModal && (
          <DuplicatesModal
            close={() => {
              setOpenDuplicatesModal(false);
              getData();
            }}
          />
        )}
        {openLogModal && <LogModal close={() => setOpenLogModal(false)} />}
      </div>
      {isAdmin && unlinkedExams.length > 0 && (
        <div className="fixed bottom-0 left-0 w-full z-50 overflow-x-auto bg-white">
          <div className="w-full p-4 whitespace-nowrap flex items-center">
            Termes extrait non liés à un code :
            {unlinkedExams.map((exam) => (
              <div key={exam._id} className="rounded-md bg-gray-100 px-2 py-1 m-1 flex w-fit items-center">
                <button type="button">
                  <HiOutlineX onClick={() => hiddeUnlinkedExam(exam._id)} />
                </button>
                {exam.values[0]}
                {exam.prescriptions?.length > 0 && (
                  <button
                    type="button"
                    onClick={() => window.open(`/explorer?q=${exam.prescriptions[0].silId}&selectedPrescription=${exam.prescriptions[0]._id}`, "_blank")}
                    className="ml-1 hover:text-blue-500"
                  >
                    <HiExternalLink />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const AddModal = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const [examTypePriority, setExamTypePriority] = useState([]);
  const [sampleId, setSampleId] = useState({});
  const [codes, setCodes] = useState({
    [EXAM_TYPE.SANG]: "",
  });
  const [exams, setExams] = useState({
    [EXAM_TYPE.SANG]: "",
  });

  useEffect(() => {
    setExams((prev) => {
      const types = Object.keys(codes).filter((type) => codes[type] !== undefined);

      const examsToRemove = Object.keys(prev).filter((type) => !types.includes(type));
      const examsToAdd = types.filter((type) => prev[type] === undefined);

      let newExams = JSON.parse(JSON.stringify(prev));
      examsToRemove.forEach((type) => {
        newExams[type] = undefined;
      });

      examsToAdd.forEach((type) => {
        newExams[type] = "";
      });

      if (prev[EXAM_TYPE.DEFAULT]) newExams[EXAM_TYPE.DEFAULT] = prev[EXAM_TYPE.DEFAULT];
      else if (types.filter((type) => type !== EXAM_TYPE.ECHANTILLON && type !== EXAM_TYPE.H24).length > 1) newExams[EXAM_TYPE.DEFAULT] = "";
      return newExams;
    });

    if (codes[EXAM_TYPE.PCR] === undefined || codes[EXAM_TYPE.SEROLOGIE] === undefined) setExamTypePriority(DEFAULT_EXAM_TYPE_PRIORITY);
  }, [codes]);

  const addCode = async () => {
    try {
      let body = { codes: [], exams: [], EXAM_TYPE_PRIORITY: examTypePriority };

      for (const [type, value] of Object.entries(codes)) {
        if (!value) continue;
        body.codes.push(
          ...value
            .split(",")
            .filter((value) => value.length > 0)
            .map((value) => ({ type, value: value.trim(), sampleId: sampleId[type]?.trim() || "" }))
        );
      }

      for (const [type, value] of Object.entries(exams)) {
        if (!value) continue;
        body.exams.push(
          ...value
            .split(",")
            .filter((value) => value.length > 0)
            .map((value) => ({ type, value: value.trim() }))
        );
      }

      if (!body.codes.length) return toast.error("Merci de saisir un code");
      if (!body.exams.length) return toast.error("Merci de saisir un examen");
      setLoading(true);

      const { ok } = await API.post("/table", body);

      if (!ok) return toast.error("Une erreur est survenue");

      toast.success("Code ajouté avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={close}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Ajouter un code</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <p className="text-lg font-semibold mt-4">Type de code</p>
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.SANG] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.SANG]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.SANG]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Sang</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.SEROLOGIE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.SEROLOGIE]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.SEROLOGIE]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Sérologie</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.PCR] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.PCR]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.PCR]: undefined,
                  }));
                }}
              />
              <p className="mr-4">PCR</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.URINE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.URINE]: "",
                      [EXAM_TYPE.ECHANTILLON]: "",
                      [EXAM_TYPE.H24]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.URINE]: undefined,
                    [EXAM_TYPE.ECHANTILLON]: undefined,
                    [EXAM_TYPE.H24]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Urine</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.SELLE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.SELLE]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.SELLE]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Selle</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.AUTRE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.AUTRE]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.AUTRE]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Autre</p>
            </div>

            <div className="mt-4 text-lg font-semibold">Codes</div>
            <div className="grid grid-cols-5 items-center gap-x-2 gap-y-1">
              {codes[EXAM_TYPE.SANG] !== undefined && (
                <>
                  <div>Sang :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.SANG]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SANG]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={sampleId[EXAM_TYPE.SANG]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSampleId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SANG]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.SEROLOGIE] !== undefined && (
                <>
                  <div>Sérologie :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.SEROLOGIE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SEROLOGIE]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={sampleId[EXAM_TYPE.SEROLOGIE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSampleId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SEROLOGIE]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.PCR] !== undefined && (
                <>
                  <div>PCR :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.PCR]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.PCR]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={sampleId[EXAM_TYPE.PCR]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSampleId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.PCR]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.ECHANTILLON] !== undefined && (
                <>
                  <div>Urine - Échantillon :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.ECHANTILLON]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.ECHANTILLON]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={sampleId[EXAM_TYPE.ECHANTILLON]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSampleId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.ECHANTILLON]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.H24] !== undefined && (
                <>
                  <div>Urine - 24h :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.H24]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.H24]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={sampleId[EXAM_TYPE.H24]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSampleId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.H24]: value,
                      }));
                    }}
                  />
                </>
              )}

              {codes[EXAM_TYPE.SELLE] !== undefined && (
                <>
                  <div>Selle :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.SELLE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SELLE]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={sampleId[EXAM_TYPE.SELLE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSampleId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SELLE]: value,
                      }));
                    }}
                  />
                </>
              )}

              {codes[EXAM_TYPE.AUTRE] !== undefined && (
                <>
                  <div>Autre :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.AUTRE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.AUTRE]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={sampleId[EXAM_TYPE.AUTRE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSampleId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.AUTRE]: value,
                      }));
                    }}
                  />
                </>
              )}
            </div>
            <div className="mt-4">
              <div className="text-lg font-semibold">Examens</div>

              <div className="grid grid-cols-5 items-center gap-x-2 gap-y-1">
                {exams[EXAM_TYPE.DEFAULT] !== undefined && (
                  <>
                    <div>Commun à tous les codes :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.DEFAULT]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.DEFAULT]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.SANG] !== undefined && (
                  <>
                    <div>Sang :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.SANG]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.SANG]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.SEROLOGIE] !== undefined && (
                  <>
                    <div>Sérologie :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.SEROLOGIE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.SEROLOGIE]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.PCR] !== undefined && (
                  <>
                    <div>PCR :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.PCR]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.PCR]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.URINE] !== undefined && (
                  <>
                    <div>Urine - Échantillon ou 24h :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.URINE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.URINE]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.ECHANTILLON] !== undefined && (
                  <>
                    <div>Urine - Échantillon :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.ECHANTILLON]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.ECHANTILLON]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.H24] !== undefined && (
                  <>
                    <div>Urine - 24h :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.H24]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.H24]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.SELLE] !== undefined && (
                  <>
                    <div>Selle :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.SELLE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.SELLE]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.AUTRE] !== undefined && (
                  <>
                    <div>Autre :</div>
                    <textarea
                      className="h-32 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.AUTRE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.AUTRE]: value,
                        }));
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {exams[EXAM_TYPE.PCR] !== undefined && exams[EXAM_TYPE.SEROLOGIE] !== undefined && (
              <div className="mt-4">
                <div className="text-lg font-semibold">Options</div>
                <div className="flex items-center gap-x-2 mt-2">
                  <input
                    type="checkbox"
                    checked={JSON.stringify(examTypePriority) === JSON.stringify(PCR_EXAM_TYPE_PRIORITY)}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setExamTypePriority(isChecked ? PCR_EXAM_TYPE_PRIORITY : DEFAULT_EXAM_TYPE_PRIORITY);
                    }}
                  />
                  <div>En cas d'absence de contexe, les codes PCR sont prioritaires sur les codes Sérologie </div>
                </div>
              </div>
            )}
            <div className="flex justify-end mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={addCode}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditModal = ({ selectedCode, close }) => {
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState({});
  const [samplesId, setSamplesId] = useState({});
  const [exams, setExams] = useState({});
  const [examTypePriority, setExamTypePriority] = useState([]);

  useEffect(() => {
    let newCodes = {};
    let newSamplesId = {};
    for (const code of selectedCode.codes) {
      newSamplesId[code.type] = code.sampleId;

      if (!newCodes[code.type]) {
        newCodes[code.type] = code.value;
        continue;
      }

      newCodes[code.type] = `${newCodes[code.type]}, ${code.value}`;
    }

    let newExams = {};
    for (const exam of selectedCode.exams) {
      if (!newExams[exam.type]) {
        newExams[exam.type] = exam.value;
        continue;
      }

      newExams[exam.type] = `${newExams[exam.type]}, ${exam.value}`;
    }

    if (
      newCodes[EXAM_TYPE.URINE] ||
      newCodes[EXAM_TYPE.ECHANTILLON] ||
      newCodes[EXAM_TYPE.H24] ||
      newExams[EXAM_TYPE.URINE] ||
      newExams[EXAM_TYPE.ECHANTILLON] ||
      newExams[EXAM_TYPE.H24]
    ) {
      newCodes[EXAM_TYPE.URINE] = newCodes[EXAM_TYPE.URINE] || "";
      newCodes[EXAM_TYPE.ECHANTILLON] = newCodes[EXAM_TYPE.ECHANTILLON] || "";
      newCodes[EXAM_TYPE.H24] = newCodes[EXAM_TYPE.H24] || "";

      newExams[EXAM_TYPE.URINE] = newExams[EXAM_TYPE.URINE] || "";
      newExams[EXAM_TYPE.ECHANTILLON] = newExams[EXAM_TYPE.ECHANTILLON] || "";
      newExams[EXAM_TYPE.H24] = newExams[EXAM_TYPE.H24] || "";
    }

    setCodes(newCodes);
    setSamplesId(newSamplesId);
    setExams(newExams);
    setExamTypePriority(selectedCode.EXAM_TYPE_PRIORITY);
  }, []);

  useEffect(() => {
    if (Object.keys(codes).length === 0) return;

    setExams((prev) => {
      const types = Object.keys(codes).filter((type) => codes[type] !== undefined);

      const examsToRemove = Object.keys(prev).filter((type) => !types.includes(type));
      const examsToAdd = types.filter((type) => prev[type] === undefined);

      let newExams = JSON.parse(JSON.stringify(prev));
      examsToRemove.forEach((type) => {
        newExams[type] = undefined;
      });

      examsToAdd.forEach((type) => {
        newExams[type] = "";
      });

      if (prev[EXAM_TYPE.DEFAULT]) newExams[EXAM_TYPE.DEFAULT] = prev[EXAM_TYPE.DEFAULT];
      else if (types.filter((type) => type !== EXAM_TYPE.ECHANTILLON && type !== EXAM_TYPE.H24).length > 1) newExams[EXAM_TYPE.DEFAULT] = "";
      return newExams;
    });

    if (codes[EXAM_TYPE.PCR] === undefined || codes[EXAM_TYPE.SEROLOGIE] === undefined) setExamTypePriority(DEFAULT_EXAM_TYPE_PRIORITY);
  }, [codes]);

  const editCode = async () => {
    try {
      let body = { codes: [], exams: [], EXAM_TYPE_PRIORITY: examTypePriority };

      for (const [type, value] of Object.entries(codes)) {
        if (!value) continue;
        body.codes.push(
          ...value
            .split(",")
            .filter((value) => value.length > 0)
            .map((value) => ({ type, value: value.trim(), sampleId: samplesId[type]?.trim() || "" }))
        );
      }

      for (const [type, value] of Object.entries(exams)) {
        if (!value) continue;
        body.exams.push(
          ...value
            .split(",")
            .filter((value) => value.length > 0)
            .map((value) => ({ type, value: value.trim() }))
        );
      }

      if (!body.codes.length) return toast.error("Merci de saisir un code");
      if (!body.exams.length) return toast.error("Merci de saisir un examen");

      setLoading(true);

      const { ok } = await API.put(`/table/${selectedCode._id}`, body);

      if (!ok) return toast.error("Une erreur est survenue");

      toast.success("Code ajouté avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }

    setLoading(false);
  };

  const deleteCode = async () => {
    const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer ce code ?");
    if (!confirm) return;

    setLoading(true);

    try {
      const { ok } = await API.delete(`/table/${selectedCode._id}`);

      if (!ok) return toast.error("Une erreur est survenue");

      toast.success("Code supprimé avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }

    return;
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={close}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Éditer un code</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <p className="text-lg font-semibold mt-4">Type de code</p>
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.SANG] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.SANG]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.SANG]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Sang</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.SEROLOGIE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.SEROLOGIE]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.SEROLOGIE]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Sérologie</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.PCR] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.PCR]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.PCR]: undefined,
                  }));
                }}
              />
              <p className="mr-4">PCR</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.URINE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.URINE]: "",
                      [EXAM_TYPE.ECHANTILLON]: "",
                      [EXAM_TYPE.H24]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.URINE]: undefined,
                    [EXAM_TYPE.ECHANTILLON]: undefined,
                    [EXAM_TYPE.H24]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Urine</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.SELLE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.SELLE]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.SELLE]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Selle</p>
              <input
                type="checkbox"
                checked={codes[EXAM_TYPE.AUTRE] !== undefined}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return setCodes((prev) => ({
                      ...prev,
                      [EXAM_TYPE.AUTRE]: "",
                    }));
                  }

                  return setCodes((prev) => ({
                    ...prev,
                    [EXAM_TYPE.AUTRE]: undefined,
                  }));
                }}
              />
              <p className="mr-4">Autre</p>
            </div>

            <div className="mt-4 text-lg font-semibold">Codes</div>
            <div className="grid grid-cols-5 items-center gap-x-2 gap-y-1">
              {codes[EXAM_TYPE.SANG] !== undefined && (
                <>
                  <div>Sang :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.SANG]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SANG]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={samplesId[EXAM_TYPE.SANG]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSamplesId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SANG]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.SEROLOGIE] !== undefined && (
                <>
                  <div>Sérologie :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.SEROLOGIE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SEROLOGIE]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={samplesId[EXAM_TYPE.SEROLOGIE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSamplesId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SEROLOGIE]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.PCR] !== undefined && (
                <>
                  <div>PCR :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.PCR]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.PCR]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={samplesId[EXAM_TYPE.PCR]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSamplesId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.PCR]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.ECHANTILLON] !== undefined && (
                <>
                  <div>Urine - Échantillon :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.ECHANTILLON]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.ECHANTILLON]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={samplesId[EXAM_TYPE.ECHANTILLON]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSamplesId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.ECHANTILLON]: value,
                      }));
                    }}
                  />
                </>
              )}
              {codes[EXAM_TYPE.H24] !== undefined && (
                <>
                  <div>Urine - 24h :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.H24]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.H24]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={samplesId[EXAM_TYPE.H24]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSamplesId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.H24]: value,
                      }));
                    }}
                  />
                </>
              )}

              {codes[EXAM_TYPE.SELLE] !== undefined && (
                <>
                  <div>Selle :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.SELLE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SELLE]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={samplesId[EXAM_TYPE.SELLE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSamplesId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.SELLE]: value,
                      }));
                    }}
                  />
                </>
              )}

              {codes[EXAM_TYPE.AUTRE] !== undefined && (
                <>
                  <div>Autre :</div>
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-3"
                    placeholder="Séparer les codes par une virgule (,)"
                    value={codes[EXAM_TYPE.AUTRE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCodes((prev) => ({
                        ...prev,
                        [EXAM_TYPE.AUTRE]: value,
                      }));
                    }}
                  />
                  <input
                    className="rounded-md border px-2 py-1 flex-grow col-span-1"
                    placeholder="Code échantillon"
                    value={samplesId[EXAM_TYPE.AUTRE]}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSamplesId((prev) => ({
                        ...prev,
                        [EXAM_TYPE.AUTRE]: value,
                      }));
                    }}
                  />
                </>
              )}
            </div>
            <div className="mt-4">
              <div className="text-lg font-semibold">Examens</div>

              <div className="grid grid-cols-5 items-center gap-x-2 gap-y-1">
                {exams[EXAM_TYPE.DEFAULT] !== undefined && (
                  <>
                    <div>Commun à tous les codes :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.DEFAULT]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.DEFAULT]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.SANG] !== undefined && (
                  <>
                    <div>Sang :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.SANG]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.SANG]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.SEROLOGIE] !== undefined && (
                  <>
                    <div>Sérologie :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.SEROLOGIE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.SEROLOGIE]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.PCR] !== undefined && (
                  <>
                    <div>PCR :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.PCR]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.PCR]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.URINE] !== undefined && (
                  <>
                    <div>Urine - Échantillon ou 24h :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.URINE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.URINE]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.ECHANTILLON] !== undefined && (
                  <>
                    <div>Urine - Échantillon :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.ECHANTILLON]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.ECHANTILLON]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.H24] !== undefined && (
                  <>
                    <div>Urine - 24h :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.H24]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.H24]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.SELLE] !== undefined && (
                  <>
                    <div>Selle :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.SELLE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.SELLE]: value,
                        }));
                      }}
                    />
                  </>
                )}
                {exams[EXAM_TYPE.AUTRE] !== undefined && (
                  <>
                    <div>Autre :</div>
                    <textarea
                      className="h-16 rounded-md border px-2 py-1 col-span-4"
                      placeholder="Séparer les mots par une virgule (,)"
                      value={exams[EXAM_TYPE.AUTRE]}
                      onChange={(e) => {
                        const value = e.target.value;
                        setExams((prev) => ({
                          ...prev,
                          [EXAM_TYPE.AUTRE]: value,
                        }));
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {exams[EXAM_TYPE.PCR] !== undefined && exams[EXAM_TYPE.SEROLOGIE] !== undefined && (
              <div className="mt-4">
                <div className="text-lg font-semibold">Options</div>
                <div className="flex items-center gap-x-2 mt-2">
                  <input
                    type="checkbox"
                    checked={JSON.stringify(examTypePriority) === JSON.stringify(PCR_EXAM_TYPE_PRIORITY)}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setExamTypePriority(isChecked ? PCR_EXAM_TYPE_PRIORITY : DEFAULT_EXAM_TYPE_PRIORITY);
                    }}
                  />
                  <div>En cas d'absence de contexe, les codes PCR sont prioritaires sur les codes Sérologie </div>
                </div>
              </div>
            )}
            <div className="flex justify-between mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-red-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={deleteCode}
                disabled={loading}
              >
                <HiOutlineTrash className="mr-2 inline-block" />
                Supprimer
              </button>
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={editCode}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StatsModal = ({ selectedStats, close, getData }) => {
  const [groupCodes, setGroupCodes] = useState({});
  const [windowsOpen, setWindowsOpen] = useState([]);

  useEffect(() => {
    let groupCodes = {};

    for (const code of selectedStats.codes) {
      if (!groupCodes[code.type]) groupCodes[code.type] = [];
      groupCodes[code.type].push(code);
    }

    setGroupCodes(groupCodes);
  }, [selectedStats]);

  const hideExam = async (tableId, prescriptionId, examId) => {
    try {
      const { ok } = await API.put(`/table/${tableId}/prescription/${prescriptionId}/exam/${examId}/hide`);

      if (!ok) return toast.error("Une erreur est survenue");

      windowsOpen.find((el) => el.name === prescriptionId)?.window.close();
      await getData();
      toast.success("Examen masqué avec succès");
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="fixed inset-0 z-[60] overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            className="absolute inset-0 bg-gray-500 opacity-75"
            onClick={() => {
              windowsOpen.forEach((el) => el.window.close());
              close();
            }}
          ></div>
        </div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle "
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-between">
            <div className="text-xl font-bold">Statistiques</div>
            <HiOutlineX
              className="cursor-pointer rounded-md text-xl text-red-500"
              onClick={() => {
                windowsOpen.forEach((el) => el.window.close());
                close();
              }}
            />
          </div>

          <div className="flex gap-2 col-span-2 flex-wrap mt-4 items-center">
            {Object.keys(groupCodes)
              .sort((a, b) => DEFAULT_EXAM_TYPE_PRIORITY.indexOf(a) - DEFAULT_EXAM_TYPE_PRIORITY.indexOf(b))
              .map((type) => (
                <p key={type} className={`rounded-md ${EXAM_TYPE_COLOR_BG[type]} px-2 py-1 m-1 whitespace-pre`}>
                  <span className={`text-xs ${EXAM_TYPE_COLOR_TEXT[type]}`}>{type} : </span>
                  {groupCodes[type].map((code) => code.value).join(", ")}
                </p>
              ))}
          </div>

          <div className="mt-4 overflow-x-auto overflow-auto max-h-[75vh]">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Mots-clés
                  </th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nombre d'occurences
                  </th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Pourcentage de suppression
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Exemple de prescription avec des suppressions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {selectedStats.exams
                  .sort((a, b) => {
                    if (a.stats?.percentage === undefined) return 1;
                    if (b.stats?.percentage === undefined) return -1;
                    return b.stats?.percentage - a.stats?.percentage;
                  })
                  .map((exam) => (
                    <tr key={exam._id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">{exam.value}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-center">{exam.stats?.count || 0}</td>
                      <td
                        className={`px-6 py-4 whitespace-nowrap text-sm text-center font-medium
                      ${exam.stats?.percentage > 5 ? "text-red-500" : "text-green-500"}
                      `}
                      >
                        {Math.round(exam.stats?.percentage) || 0}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap gap-2 flex-col flex">
                        {exam.stats?.prescriptionWithDeleted.map((prescription) => (
                          <div key={prescription._id} className="text-xs px-2 py-1 bg-gray-100 rounded-md w-fit flex items-center gap-1">
                            <button type="button" onClick={() => hideExam(selectedStats._id, prescription._id, exam._id)}>
                              <HiOutlineX className="inline-block text-red-500" />
                            </button>
                            <div className="text-sm">
                              [{prescription.codes.join(", ")}] {prescription.text} :{" "}
                              <span
                                className="text-blue-500 cursor-pointer"
                                onClick={() => {
                                  const newWindow = window.open(`/explorer?q=${prescription.silId}&selectedPrescription=${prescription._id}`, "_blank");
                                  setWindowsOpen((prev) => [...prev, { name: prescription._id, window: newWindow }]);
                                }}
                              >
                                {prescription.silId}
                              </span>
                            </div>
                          </div>
                        ))}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const DuplicatesModal = ({ close }) => {
  const [duplicates, setDuplicates] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { ok, data } = await API.get("/table/duplicates");
      if (!ok) return;

      setDuplicates(data);
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
  };

  const deleteExam = async (tableId, examId) => {
    try {
      const { ok } = await API.delete(`/table/${tableId}/exam/${examId}`);
      if (!ok) return toast.error("Une erreur est survenue");

      await getData();
      toast.success("Examen supprimé avec succès");
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={close}></div>
        </div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-between pb-4 border-b border-gray-200">
            <div className="text-xl font-bold">Supprimer les doublons</div>
            <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
          </div>

          {duplicates.map((duplicate) => (
            <div key={duplicate.value} className="flex gap-4 items-center border-b border-gray-200 py-2">
              {duplicate.exams
                .sort((a, b) => DEFAULT_EXAM_TYPE_PRIORITY.indexOf(a.type) - DEFAULT_EXAM_TYPE_PRIORITY.indexOf(b.type))
                .map((exam) => {
                  let codes = exam.codes
                    .filter((code) => code.type === exam.type)
                    .map((code) => code.value)
                    .join(", ");

                  if (!codes) {
                    codes = exam.codes
                      .filter((code) => code.type === EXAM_TYPE.SANG)
                      .map((code) => code.value)
                      .join(", ");
                  }

                  return (
                    <div className={`rounded-md ${EXAM_TYPE_COLOR_BG[exam.type]} px-2 py-1 m-1 whitespace-pre flex gap-2 items-center`} key={exam._id}>
                      <p>
                        <span className={`text-xs ${EXAM_TYPE_COLOR_TEXT[exam.type]} mr-2`}>{codes}</span>
                        {exam.value}
                      </p>
                      <button type="button">
                        <HiOutlineTrash className="inline-block text-red-500" onClick={() => deleteExam(exam.tableId, exam._id)} />
                      </button>
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const LogModal = ({ close }) => {
  const [logs, setLogs] = useState([]);

  const getData = async () => {
    try {
      const res = await API.get("/log");
      if (!res.ok) return toast.error(res.message);

      setLogs(res.data);
    } catch (error) {
      toast.error(error.code);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={close}></div>
        </div>
        <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-between pb-4 border-b border-gray-200">
            <div className="text-xl font-bold">Historique des changements</div>
            <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
          </div>

          <div className="divide-y divide-gray-200">
            <div className="bg-gray-50 grid grid-cols-10 gap-4 px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center">
              <div className="col-span-2">Metadata</div>
              <div className="col-span-8">Data</div>
            </div>
            <div className="bg-white divide-y divide-gray-200">
              {logs.map((log, index) => (
                <div key={index} className="grid grid-cols-10 gap-4 items-center p-2">
                  <div className="col-span-2 break-all">
                    <div>{new Date(log.createdAt).toLocaleString()}</div>
                    <div>{log.userEmail}</div>
                  </div>
                  <div className="col-span-8 break-all whitespace-pre-wrap">{log.data}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Code;
