import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HiOutlinePlus, HiCheck, HiOutlineX, HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import { toast } from "react-hot-toast";

import API from "../api";

const Rules = ({}) => {
  const history = useHistory();
  const [rules, setRules] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);

  const getData = async () => {
    try {
      const response = await API.get("/rule");
      if (!response.ok) return toast.error(response.message);

      setRules(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="px-4 pb-16">
      <div className="divide-y divide-gray-200 mt-2">
        <div className="bg-gray-50 grid grid-cols-8 px-2 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-center">
          <div className="col-span-1">Numéro</div>
          <div className="col-span-3">Codes remplacés</div>
          <div className="col-span-3">Codes insérés à la place</div>
          <div className="col-span-1 flex justify-end">
            <button type="button" className="text-blue-500 text-lg" onClick={() => setOpenAddModal(true)}>
              <HiOutlinePlus />
            </button>
          </div>
        </div>
        <div className="bg-white divide-y divide-gray-200">
          {rules.map((rule, index) => (
            <div key={index} className="grid grid-cols-8 items-center p-2">
              <div className="col-span-1">{index + 1}</div>
              <div className="col-span-3">{rule.triggerCodes.join(", ")}</div>
              <div className="col-span-3">{rule.replacementCodes.join(", ")}</div>
              <div className="col-span-1 flex justify-end">
                <button type="button" className="text-blue-500 text-lg" onClick={() => setSelectedRule(rule)}>
                  <HiPencilAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {openAddModal && (
        <AddModal
          close={() => {
            setOpenAddModal(false);
            getData();
          }}
        />
      )}
      {selectedRule && (
        <EditModal
          selectedRule={selectedRule}
          close={() => {
            setSelectedRule(null);
            getData();
          }}
        />
      )}
    </div>
  );
};

const AddModal = ({ close }) => {
  const [loading, setLoading] = useState(false);
  const [triggerCodes, setTriggerCodes] = useState("");
  const [replacementCodes, setReplacementCodes] = useState("");

  const addRule = async () => {
    if (!triggerCodes || !replacementCodes) return toast.error("Veuillez remplir tous les champs");

    try {
      setLoading(true);
      let triggerCodesInArray = triggerCodes.split(",");
      let replacementCodesInArray = replacementCodes.split(",");

      triggerCodesInArray = triggerCodesInArray.map((code) => code.trim()).filter((code) => code !== "");
      replacementCodesInArray = replacementCodesInArray.map((code) => code.trim()).filter((code) => code !== "");

      const response = await API.post("/rule", { triggerCodes: triggerCodesInArray, replacementCodes: replacementCodesInArray });
      if (!response.ok) return toast.error(response.message);

      toast.success("Règle ajoutée avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Ajouter une règle</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <p className="text-lg font-semibold mt-4">Codes remplacés</p>
            <input
              className="mt-2 p-2 w-full border rounded-md"
              placeholder="Séparer les codes par une virgule (,)"
              value={triggerCodes}
              onChange={(e) => setTriggerCodes(e.target.value)}
            />
            <p className="text-lg font-semibold mt-4">Codes insérés à la place</p>
            <input
              className="mt-2 p-2 w-full border rounded-md"
              placeholder="Séparer les codes par une virgule (,)"
              value={replacementCodes}
              onChange={(e) => setReplacementCodes(e.target.value)}
            />
            <div className="mt-4">
              Lors que une ordonnance contient l'ensemble des codes suivants : <span className="font-bold">{triggerCodes}</span>. Ils seront remplacés par les codes :{" "}
              <span className="font-bold">{replacementCodes}</span>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={addRule}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditModal = ({ selectedRule, close }) => {
  const [loading, setLoading] = useState(false);
  const [triggerCodes, setTriggerCodes] = useState("");
  const [replacementCodes, setReplacementCodes] = useState("");

  useEffect(() => {
    setTriggerCodes(selectedRule.triggerCodes.join(", "));
    setReplacementCodes(selectedRule.replacementCodes.join(", "));
  }, [selectedRule]);

  const updateRule = async () => {
    if (!triggerCodes || !replacementCodes) return toast.error("Veuillez remplir tous les champs");

    try {
      setLoading(true);
      let triggerCodesInArray = triggerCodes.split(",");
      let replacementCodesInArray = replacementCodes.split(",");

      triggerCodesInArray = triggerCodesInArray.map((code) => code.trim()).filter((code) => code !== "");
      replacementCodesInArray = replacementCodesInArray.map((code) => code.trim()).filter((code) => code !== "");

      const response = await API.put(`/rule/${selectedRule._id}`, { triggerCodes: triggerCodesInArray, replacementCodes: replacementCodesInArray });
      if (!response.ok) return toast.error("Une erreur est survenue");

      toast.success("Règle mise à jour avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }

    setLoading(false);
  };

  const deleteRule = async () => {
    const confirm = window.confirm("Êtes-vous sûr de vouloir supprimer cette règle ?");
    if (!confirm) return;

    setLoading(true);
    try {
      const response = await API.delete(`/rule/${selectedRule._id}`);
      if (!response.ok) return toast.error("Une erreur est survenue");

      toast.success("Règle supprimée avec succès");
      close();
    } catch (e) {
      console.log(e);
      toast.error("Une erreur est survenue");
    }

    return;
  };

  return (
    <div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => close()}></div>
          </div>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block transform rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle overflow-auto max-h-[80vh]"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">Modifier une règle</div>
              <HiOutlineX className="cursor-pointer rounded-md text-xl text-red-500" onClick={close} />
            </div>

            <p className="text-lg font-semibold mt-4">Codes remplacés</p>
            <input
              className="mt-2 p-2 w-full border rounded-md"
              placeholder="Séparer les codes par une virgule (,)"
              value={triggerCodes}
              onChange={(e) => setTriggerCodes(e.target.value)}
            />
            <p className="text-lg font-semibold mt-4">Codes insérés à la place</p>
            <input
              className="mt-2 p-2 w-full border rounded-md"
              placeholder="Séparer les codes par une virgule (,)"
              value={replacementCodes}
              onChange={(e) => setReplacementCodes(e.target.value)}
            />
            <div className="mt-4">
              Lors que une ordonnance contient l'ensemble des codes suivants : <span className="font-bold">{triggerCodes}</span>. Ils seront remplacés par les codes :{" "}
              <span className="font-bold">{replacementCodes}</span>
            </div>
            <div className="flex justify-between mt-4">
              <button
                className={`flex cursor-pointer items-center rounded-md bg-red-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={deleteRule}
                disabled={loading}
              >
                <HiOutlineTrash className="mr-2 inline-block" />
                Supprimer
              </button>
              <button
                className={`flex cursor-pointer items-center rounded-md bg-green-500 px-2 py-1 text-white ${loading && "opacity-50 cursor-not-allowed"}`}
                onClick={updateRule}
                disabled={loading}
              >
                <HiCheck className="mr-2 inline-block" />
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
