import React from "react";
import { BiLoader } from "react-icons/bi";

export default function Loading() {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <BiLoader className="animate-spin text-5xl text-blue-900" />
    </div>
  );
}
