import React, { useEffect, useState, useCallback, useRef } from "react";
import { HiExclamation, HiOutlineX, HiCheck, HiOutlinePlus } from "react-icons/hi";
import { toast } from "react-hot-toast";
import { API_URL } from "../config";

import API from "../api";

const Explorer = ({ user }) => {
  const fileInputRef = useRef(null);
  const params = new URLSearchParams(window.location.search);
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [filter, setFilter] = useState({
    limit: 200,
    date: params.get("q") ? "" : new Date().toISOString().slice(0, 10),
    codeDeleted: params.get("codeDeleted") === "true",
    codeAddedWithoutWarning: params.get("codeAddedWithoutWarning") === "true",
    q: params.get("q") || "",
  });
  const [debouncedFilter, setDebouncedFilter] = useState(filter);

  let isSuperAdmin = user.role === "superadmin";

  const displayPolygons = useCallback(async () => {
    if (!selectedPrescription) return;

    const imageElement = document.getElementById("displayImage");
    if (!imageElement) return;

    setPolygons([]);

    for (const act of selectedPrescription.acts) {
      if (!act.polygon.length) continue;

      const adjustedPoints = act.polygon.map((point) => ({
        x: point.x * imageElement.clientWidth,
        y: point.y * imageElement.clientHeight,
      }));

      const pointsString = adjustedPoints.map((point) => `${point.x}px ${point.y}px`).join(", ");

      const className = `querco_polygon_${act._id}`;
      setPolygons((prev) => {
        const existingPolygon = prev.find((polygon) => polygon.pointsString === pointsString);
        if (existingPolygon) {
          existingPolygon.className += ` ${className}`;
          existingPolygon.ALD = existingPolygon.ALD || act.ALD;
          existingPolygon.isDeleted = existingPolygon.isDeleted || (act.isDeleted && act.codesPredicted.length !== 0);
          existingPolygon.codeNotFound = existingPolygon.codeNotFound || act.codesPredicted.length === 0;
          existingPolygon.codeNotInserted = existingPolygon.codeNotInserted || (act.notInserted && act.codesPredicted.length !== 0);

          return prev;
        }

        return [
          ...prev,
          {
            pointsString,
            className,
            ALD: act.ALD,
            isDeleted: act.isDeleted && act.codesPredicted?.length !== 0,
            codeNotFound: act.codesPredicted.length === 0,
            codeNotInserted: act.notInserted && act.codesPredicted.length !== 0,
          },
        ];
      });
    }
  }, [selectedPrescription?._id]);

  const getData = async () => {
    const toastId = toast.loading("Chargement des données...");
    try {
      const response = await API.get(
        `/prescription/explorer?limit=${debouncedFilter.limit}&date=${debouncedFilter.date}&codeDeleted=${debouncedFilter.codeDeleted}&codeAddedWithoutWarning=${debouncedFilter.codeAddedWithoutWarning}&q=${debouncedFilter.q}&flagged=${debouncedFilter.flagged}`
      );
      if (!response.ok) return toast.error(response.message, { id: toastId });

      toast.success("Données chargées", { id: toastId });
      setPrescriptions(response.data);

      if (selectedPrescription) return;

      const selectedPrescriptionId = params.get("selectedPrescription");
      if (selectedPrescriptionId) setSelectedPrescription(response.data.find((p) => p._id === selectedPrescriptionId));
      else setSelectedPrescription(response.data[0]);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue", { id: toastId });
    }
  };

  useEffect(() => {
    getData();
  }, [debouncedFilter]);

  useEffect(() => {
    displayPolygons();
  }, [selectedPrescription?._id]);

  useEffect(() => {
    const timerId = setTimeout(() => setDebouncedFilter(filter), 1000);

    return () => clearTimeout(timerId);
  }, [filter]);

  const flagPrescription = async (prescription, flag) => {
    const toastId = toast.loading("Signalement...");
    try {
      const response = await API.put(`/prescription/${prescription._id}/flag`, { flag });
      if (!response.ok) return toast.error(response.message, { id: toastId });

      toast.success("Dossier signalé", { id: toastId });
      if (prescription._id === selectedPrescription._id) setSelectedPrescription((prev) => ({ ...prev, flags: response.data.flags }));
      await getData();
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue", { id: toastId });
    }
  };

  const newExtraction = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const toastId = toast.loading("Extraction en cours...");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("formMode", false);

      const response = await fetch(`${API_URL}/v1/prescription`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Api-Key ${user.apikey}`,
        },
      });

      if (!response.ok) {
        const data = await response.text();
        throw new Error(data);
      }

      const json = await response.json();
      if (!json.ok) throw new Error(json);
      const { _id } = json.data;

      window.location.href = `/explorer?q=${_id}`;
      await getData();
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue", { id: toastId });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        const currentIndex = prescriptions.findIndex((p) => p._id === selectedPrescription?._id);
        if (currentIndex !== -1) {
          const nextIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;
          if (nextIndex >= 0 && nextIndex < prescriptions.length) {
            setSelectedPrescription(prescriptions[nextIndex]);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [prescriptions, selectedPrescription]);

  return (
    <div className="px-4 ">
      <div className="grid grid-cols-12">
        <div className=" col-span-2 h-[calc(100vh-110px)]">
          <div className="text-xl font-bold">Filtres</div>
          <div className="flex items-center gap-2">
            <input
              type="date"
              id="dateFilter"
              className="p-2 border rounded-md"
              value={filter.date}
              max={new Date().toISOString().slice(0, 10)}
              onChange={(e) => setFilter({ ...filter, date: e.target.value })}
            />
            <button className="text-red-500 text-lg" onClick={() => setFilter({ ...filter, date: "" })}>
              <HiOutlineX />
            </button>
          </div>
          <input
            type="text"
            value={filter.q}
            placeholder="Rechercher par numéro de dossier ou codes"
            className="w-full p-2 mt-2 border rounded-md mb-2"
            onChange={(e) => setFilter({ ...filter, q: e.target.value })}
          />
          <div className="flex items-center gap-2">
            <input type="checkbox" id="codeDeleted" checked={filter.codeDeleted} onChange={() => setFilter({ ...filter, codeDeleted: !filter.codeDeleted })} />
            Codes supprimés
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="codeAddedWithoutWarning"
              checked={filter.codeAddedWithoutWarning}
              onChange={() => setFilter({ ...filter, codeAddedWithoutWarning: !filter.codeAddedWithoutWarning })}
            />
            Codes ajoutés sans alertes
          </div>
          {isSuperAdmin && (
            <div className="flex items-center gap-2">
              <input type="checkbox" id="flagged" checked={filter.flagged} onChange={() => setFilter({ ...filter, flagged: !filter.flagged })} />
              Ordonnances signalées
            </div>
          )}

          <div className="flex items-center mt-4 gap-2 justify-between">
            <div className="text-xl font-bold">Ordonnances</div>
            <button type="button" className="text-[#026D77] text-xl" onClick={() => fileInputRef.current.click()}>
              <HiOutlinePlus />
            </button>
            <input type="file" id="upload" accept="image/*,application/pdf" className="hidden" ref={fileInputRef} onChange={newExtraction} />
          </div>
          <div className="bg-white overflow-auto max-h-[calc(100vh-350px)]">
            {prescriptions.map((prescription) => (
              <button
                key={prescription._id}
                className={`w-full text-left p-2 border-t border-gray-200 ${
                  selectedPrescription && selectedPrescription._id === prescription._id ? "bg-[#026D77] text-white rounded-md" : ""
                }`}
                onClick={() => setSelectedPrescription(prescription)}
              >
                {new Date(prescription.createdAt).toLocaleString().slice(0, 5)} - {prescription.silId || prescription.type}
              </button>
            ))}
            <div className="w-full flex justify-center py-4 border-t border-gray-200">
              <button
                disabled={prescriptions.length <= filter.limit}
                className={`p-2 rounded-md border border-[#026D77] text-[#026D77] ${prescriptions.length <= filter.limit ? "opacity-50" : ""}`}
                onClick={() => setFilter({ ...filter, limit: filter.limit + 100 })}
              >
                Charger plus d'ordonnances
              </button>
            </div>
          </div>
        </div>
        {selectedPrescription && (
          <>
            <div className="col-span-6 flex justify-center">
              <div className="relative">
                <img src={selectedPrescription.img} alt="Ordonnance" className="max-h-[calc(100vh-110px)]" id="displayImage" onLoad={displayPolygons} />
                {polygons &&
                  polygons.map((polygon, index) => (
                    <div
                      key={index}
                      className={`absolute top-0 left-0 w-full h-full z-[220] opacity-25 ${polygon.className} ${
                        polygon.isDeleted
                          ? "bg-red-500"
                          : polygon.codeNotFound
                          ? "bg-orange-500"
                          : polygon.codeNotInserted
                          ? "bg-blue-500"
                          : polygon.ALD
                          ? "bg-purple-500"
                          : "bg-green-500"
                      }`}
                      style={{ clipPath: `polygon(${polygon.pointsString})` }}
                      mongoid={polygon.mongoid}
                    ></div>
                  ))}
              </div>
            </div>

            <div className=" col-span-4 overflow-auto h-[calc(100vh-110px)] mb-16">
              <div className="mt-4">{new Date(selectedPrescription.createdAt).toLocaleString().slice(0, 10)}</div>
              <h3 className="text-2xl font-bold mb-4">{selectedPrescription.silId}</h3>

              {Object.values(selectedPrescription.warning).some((el) => el === true) && (
                <div className="mb-4 border-2 border-orange-500 bg-orange-100 p-2 rounded-md">
                  <div className="font-bold text-orange-500">Vérifications</div>
                  <div>
                    {selectedPrescription.warning.isHandwritten && <div>- Les textes manuscrits</div>}
                    {selectedPrescription.warning.hasExamWithoutCode && <div>- Que tous les examens sur l'ordonnance sont surlignés</div>}
                    {selectedPrescription.warning.hasAdditionalInfo && <div>- Infos complémentaires (urgent, transmission, renouvellement, date de réalisation ...)</div>}
                    {selectedPrescription.warning.hasRenewal && <div>- Renouvellement</div>}
                    {selectedPrescription.warning.hasSchedule && <div>- Date de réalisation</div>}
                    {selectedPrescription.warning.hasConditionNotTakenIntoAccount && <div>- Que les conditions (si, +/-) soient respectées</div>}
                    {selectedPrescription.warning.hasCrossedOut && <div>- Textes barrés</div>}
                  </div>
                </div>
              )}

              <div>
                <span className="font-bold">Date : </span> {selectedPrescription.date}
              </div>
              <div>
                <span className="font-bold">Doctor : </span> {selectedPrescription.doctor?.RPPS || "N/A"} | {selectedPrescription.doctor?.ADELI || "N/A"}
              </div>
              <div>
                <span className="font-bold">Examens :</span>
                {selectedPrescription.acts.map((act, index) => (
                  <span
                    key={index}
                    className={`border-2 inline-block m-1 py-0.25 px-0.5 rounded-md ${
                      act.codes.length > 0 && act.codesPredicted.length === 0
                        ? "border-yellow-500 bg-yellow-100"
                        : act.isDeleted && act.codesPredicted.length !== 0
                        ? "border-red-500 bg-red-100"
                        : act.codes.length === 0
                        ? "border-orange-500 bg-orange-100"
                        : act.notInserted && act.codesPredicted.length !== 0
                        ? "border-blue-500 bg-blue-100"
                        : act.ALD
                        ? "border-purple-500 bg-purple-100"
                        : "border-green-500 bg-green-100"
                    }`}
                    onMouseEnter={() => {
                      const codeElement = document.querySelectorAll(`.querco_polygon_${act._id}`);
                      if (codeElement) codeElement.forEach((element) => (element.style.opacity = 0.75));
                    }}
                    onMouseLeave={() => {
                      const codeElement = document.querySelectorAll(`.querco_polygon_${act._id}`);
                      if (codeElement) codeElement.forEach((element) => (element.style.opacity = 0.25));
                    }}
                  >
                    {act.text || "N/A"} : {act.codes.join(", ") || act.codesPredicted.join(", ") || "N/A"}
                  </span>
                ))}
              </div>

              <div className="flex justify-center gap-4 mt-16 2xl:flex-row 2xl:w-full flex-col w-fit">
                <button
                  className={`px-2 py-1 rounded-md flex items-center gap-2 ${
                    selectedPrescription.flags?.includes("MISSING_EXAM") ? "text-white bg-red-500" : "text-red-500 bg-red-100"
                  }`}
                  onClick={() => flagPrescription(selectedPrescription, "MISSING_EXAM")}
                >
                  <HiExclamation />
                  {selectedPrescription.flags?.includes("MISSING_EXAM") ? "Examen manquant signalé" : "Signaler un examen non-souligné"}
                </button>
                <button
                  className={`px-2 py-1 rounded-md flex items-center gap-2 ${
                    selectedPrescription.flags?.includes("TRADUCTION_ERROR") ? "text-white bg-red-500" : "text-red-500 bg-red-100"
                  }`}
                  onClick={() => flagPrescription(selectedPrescription, "TRADUCTION_ERROR")}
                >
                  <HiExclamation />
                  {selectedPrescription.flags?.includes("TRADUCTION_ERROR") ? "Erreur de traduction signalée" : "Signaler une erreur de traduction"}
                </button>
                {isSuperAdmin && (
                  <button
                    className={`px-2 py-1 rounded-md flex items-center gap-2 ${
                      selectedPrescription.flags?.includes("OK") ? "text-white bg-green-500" : "text-green-500 bg-green-100"
                    }`}
                    onClick={() => flagPrescription(selectedPrescription, "OK")}
                  >
                    <HiCheck />
                    OK
                  </button>
                )}
              </div>
              {isSuperAdmin && selectedPrescription?.hprim?.data && (
                <div>
                  <div className="text-xl font-bold mt-8">HPRIM</div>
                  <div>{selectedPrescription?.hprim?.data}</div>
                </div>
              )}
              {isSuperAdmin && (
                <div>
                  <div className="text-xl font-bold mt-8">NER</div>
                  {selectedPrescription?.ner?.map((ner) => (
                    <div key={ner._id}>
                      <span className="font-bold">{ner.category} : </span> {ner.content}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
        <div className="fixed bottom-5 right-5 text-sm">
          <span className="border-2 rounded-md p-1 m-1 border-yellow-500 bg-yellow-100">Code(s) ajouté(s)</span>
          <span className="border-2 rounded-md p-1 m-1 border-red-500 bg-red-100">Code(s) supprimé(s)</span>
          <span className="border-2 rounded-md p-1 m-1 border-orange-500 bg-orange-100">Code(s) non trouvé(s)</span>
          <span className="border-2 rounded-md p-1 m-1 border-blue-500 bg-blue-100">Code(s) refusé(s) par le SIL</span>
          <span className="border-2 rounded-md p-1 m-1 border-purple-500 bg-purple-100">Code(s) inséré(s) en ALD</span>
          <span className="border-2 rounded-md p-1 m-1 border-green-500 bg-green-100">Code(s) inséré(s)</span>
        </div>
      </div>
    </div>
  );
};

export default Explorer;
