import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory, NavLink, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as Sentry from "@sentry/browser";
import { HiExternalLink, HiUserAdd, HiOutlineLogout } from "react-icons/hi";

import { environment, SentryUrl } from "./config";
import API from "./api";
import Loading from "./components/Loading";

import Auth from "./scenes/auth";
import ResetPassword from "./scenes/resetPassword";
import Annotation from "./scenes/annotation";
import Code from "./scenes/code";
import Stats from "./scenes/stats";
import Rules from "./scenes/rules";
import Explorer from "./scenes/explorer";
import Sandbox from "./scenes/sandbox";

if (environment === "production" || environment === "staging") {
  Sentry.init({ dsn: SentryUrl });
}

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await API.get("/user/signin_token");
        if (!res.ok || !res.user || !res.token) return;

        API.setToken(res.token);
      } catch (e) {
        if (e.code !== 401) toast.error(e.message);
      }

      setLoading(false);
    })();
  }, []);

  if (loading)
    return (
      <div className="flex-center h-screen w-full">
        <Loading />
      </div>
    );

  return (
    <Router>
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/reset-password" component={ResetPassword} />
        <RestrictedRoute path="/annotation" component={Annotation} />
        <RestrictedRoute path="/stats" component={Stats} />
        <RestrictedRoute path="/rules" component={Rules} />
        <RestrictedRoute path="/explorer" component={Explorer} />
        <RestrictedRoute path="/querco/sandbox" component={Sandbox} setLoading={setLoading} />
        <RestrictedRoute path="/" exact component={Code} setLoading={setLoading} />
      </Switch>
    </Router>
  );
};

const RestrictedRoute = ({ component: Component, setLoading, superadmin, ...rest }) => {
  const redirect = encodeURIComponent(window.location.href.replace(window.location.origin, ""));
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invite, setInvite] = useState({ email: "", admin: false });
  const [user, setUser] = useState({ role: "user" });

  const getCurrentUser = async () => {
    try {
      const { data } = await API.get("/user/me");

      if (!data) return;

      setUser(data);
    } catch (error) {
      if (error.code === 401) return;
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const logout = async () => {
    try {
      await API.post("/user/logout");
      API.setToken("");
      history.push("/");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const inviteUser = async () => {
    setDisabled(true);
    try {
      const res = await API.post("/user/create", invite);
      if (!res.ok) return toast.error(res.message);
      toast.success("L'utilisateur a été invité avec succès");
      setIsModalVisible(false);
    } catch (error) {
      toast.error(error.message);
    }
    setDisabled(false);
  };

  useEffect(() => {
    setInvite({ email: "", admin: false });
  }, [isModalVisible]);

  let render = (props) => (
    <div>
      <div className="text-[#026D77] p-4 flex justify-between items-start">
        <div>
          <Link to="/" className="text-2xl font-bold">
            Querco - Extraction
          </Link>
          {(user.role === "admin" || user.role === "superadmin") && (
            <div className="flex items-end gap-4 mt-2">
              <NavLink to="/" exact className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Table
              </NavLink>
              <NavLink to="/rules" className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Règles d'insertion
              </NavLink>
              <NavLink to="/stats" className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Statistiques
              </NavLink>
              <NavLink to="/explorer" className={(isActive) => (isActive ? "underline underline-offset-4" : "")}>
                Explorateur
              </NavLink>
            </div>
          )}
        </div>
        {user.role === "superadmin" && <div className="text-red-500">Indexname : {user?.indexName}</div>}
        <div className="flex gap-4 items-center">
          {(user.role === "admin" || user.role === "superadmin") && (
            <>
              <button type="button" onClick={() => window.open("https://help.querco.co/", "_blank")} className="flex gap-2 items-center">
                <HiExternalLink />
                Manuel d'utilisation
              </button>
              <button type="button" onClick={() => setIsModalVisible(true)} className="flex gap-2 items-center">
                <HiUserAdd />
                Inviter un utilisateur
              </button>
            </>
          )}
          <button type="button" onClick={logout} className="flex gap-2 items-center">
            <HiOutlineLogout />
            Se deconnecter
          </button>
        </div>
      </div>
      <Component {...props} setLoading={setLoading} user={user} />
      {isModalVisible && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={() => setIsModalVisible(false)}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Inviter un utilisateur
                    </h3>
                    <div className="mt-4">
                      <p className="text-sm text-gray-500">Veuillez entrer l'adresse e-mail de l'utilisateur à inviter :</p>
                      <input
                        type="text"
                        className="mt-2 p-2 w-full border rounded-md"
                        placeholder="Adresse e-mail"
                        value={invite.email}
                        onChange={(e) => setInvite({ ...invite, email: e.target.value })}
                      />
                      <p className="text-xs text-gray-500 mt-2">Un email sera envoyé à l'utilisateur pour qu'il puisse créer un compte et accéder à l'application.</p>
                      <div className="flex items-center mt-4">
                        <input type="checkbox" className="p-2 w-4 h-4 border rounded-md" value={invite.admin} onChange={(e) => setInvite({ ...invite, admin: e.target.checked })} />
                        <label className="ml-2" htmlFor="admin">
                          Administrateur
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  disabled={disabled}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={inviteUser}
                >
                  Inviter
                </button>
                <button
                  type="button"
                  disabled={disabled}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsModalVisible(false)}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  if (!API.getToken()) {
    render = (props) => (
      <Redirect
        to={{
          search: redirect ? `?redirect=${redirect}` : "",
          pathname: "/auth",
        }}
      />
    );
  }

  return <Route {...rest} render={render} />;
};

export default App;
